import { lazy } from "react";

export const Dashboard = lazy(() => import("./Dashboard"));
export const SingleTool = lazy(() => import("./SingleTool"));
export const SingleEntry = lazy(() => import("./SingleEntry"));
export const NewEntry = lazy(() => import("./NewEntry"));
export const UpdateEntry = lazy(() => import("./UpdateEntry"));
export const LdContent = lazy(() => import("./LdContent"));
export const AccountSetup = lazy(() => import("./AccountSetup"));
export const UserObjectives = lazy(() => import("./UserObjectives"));
export const MeditationAssistant = lazy(() => import("./MeditationAssistant"));