import React, {
    Suspense, useMemo,
} from "react";

import {
    BrowserRouter as Router
} from "react-router-dom";

import { LoadingPulse } from "./components";
import { localText } from "./utils/localization";

import * as Store from "./store"

const { AppContextProvider } = Store;

const ExplusApp = ({ children, loading: Loading }) => {
    return (
        <Router>
            <Suspense fallback={Loading ? <Loading /> : <LoadingPulse />}>
                {children}
            </Suspense>
        </Router>
    );
};

export const App = ({children, loading = undefined}) => {

    const config = useMemo(() => ({
        nonce: "",
        basePath: "/",
        restBase: "/wp-json/explustools/v1/",
        lang: "en",
        activityPageSlug: false,
        isGuestUser: false,
        ...(window.appConfig || {})
    }),[]);

    if( config.lang !== "en" ) localText.setLanguage(config.lang);

    return (
        <AppContextProvider config={config}>
            <ExplusApp loading={loading}>
                {children}
            </ExplusApp>
        </AppContextProvider>
    );
}